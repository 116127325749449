import classNames from "classnames";

import { WrapperProps } from "./types";

import "./styles.scss";

export const Wrapper = ({
  children,
  customClass,
  staticPage,
  md,
  sm,
  xs,
  lg,
  xl,
}: WrapperProps) => {
  const classnames = classNames(`wrapper`, {
    [`${customClass}`]: customClass,
    "wrapper--staticPage": staticPage || customClass === "staticPage",
    "wrapper--xl": xl || customClass === "xl",
    "wrapper--lg": lg || customClass === "lg",
    "wrapper--md": md || customClass === "md",
    "wrapper--sm": sm || customClass === "sm",
    "wrapper--xs": xs || customClass === "xs",
  });

  return (
    <div
      suppressHydrationWarning={true}
      data-testid="wrapper"
      className={classnames}>
      {children}
    </div>
  );
};
