import React from "react";
import { Link } from "../Link";
import { Typography } from "@/components/Typography";
import { Banner } from "@/components/Banner";
import { BannerWrapperSize } from "@/components/Banner/types";
import { bannerProps } from "@/data/types/home";

export const MoreModule = ({
  data,
  wrapperSize,
}: {
  data: bannerProps;
  wrapperSize: BannerWrapperSize;
}) => {
  const { ctaLabel, ctaLink, richtext, data_qa } = data;

  return (
    <Banner
      big
      light
      wrapperSize={wrapperSize}>
      {richtext.map((item, i: number) => (
        <Typography
          key={i}
          Tag={item.tag}
          size={item.size}
          spacing={item.spacing}>
          {item.list
            ? item.list.map((e, i) => (
                <li key={i}>{<span dangerouslySetInnerHTML={{ __html: e["item"] }}></span>}</li>
              ))
            : item.content}
        </Typography>
      ))}
      <Link
        href={ctaLink}
        className="bold-text large button"
        type="button"
        dataQA={data_qa}
        nofollow>
        {ctaLabel}
      </Link>
    </Banner>
  );
};
